
















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getCurrentTime } from "@/utils/base";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import DsEditor from "@/components/ds-editor/ds-editor.vue";
import LeaveConfirm from "@/components/dialogs/leaveConfirmDialog.vue";
import Read from "../../components/ganyu/read.vue";
import YundongRead from "../../components/ganyu/yundong-read.vue";
import TimeRange from "@/components/time-range/time-range.vue";
import Empty from "@/components/empty.vue";
import Time from "@/components/time.vue";
import { UploadFile } from "@/components/template-transfer-pc/template-transfer/request";
import {
  saveSolution,
  getSolutionList,
  getSolutionTemList,
  getSolutionDetail,
  editSolutionTem,
  delSolutionTem,
  tijiaoFangan,
  GetZidian,
  GetYundongList,
} from "@/request/custom";
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);
@Component({
  components: {
    MainHeader,
    NavLeft,
    DsEditor,
    LeaveConfirm,
    Read,
    TimeRange,
    Empty,
    Time,
    YundongRead,
  },
})
export default class Name extends Vue {
  private routerPath: any = "";
  private name: any = ""; // 方案名称
  private mubanName: any = ""; // 另存为模板，名称
  private mubans: any = [];
  private yulanData: any = {};
  private pageType: any = ""; // 编辑 or 新增
  private id: any = "";
  private type: any = ""; // 类型（自定义方案，营养方案）
  private detail: any = {
    周期: ["", ""],
    名称: "",
    方案: {
      content: "",
    },
    filelist: [],
  };
  private IfShowEditName: any = false;
  private IfShowEditMubanName: any = false;
  private ifShowYulan: any = false;
  private ifShowSaveConfirm: any = false;
  private ifShowMuban: any = false;
  private ifShowUploadConfirm: any = false;
  private noSaveText: any = "不保存，离开";
  private cTab: any = 1;
  private yundongTypes: any = [];
  private disabledDate: any = this.endDate();
  private ifShowAddYundong: any = false;
  private addYundongData: any = {};
  private yundongs: any = [];
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private nextTimeChange(e: any) {
    this.detail["方案"]["下次评估时间"] = e;
  }
  private count() {
    if (
      this.detail["方案"]["运动计划"] &&
      this.detail["方案"]["运动计划"].length > 0
    ) {
      this.detail["方案"]["运动计划"].forEach((element: any) => {
        let time: any = null;
        let xiaohao: any = null;
        this.yundongTypes.forEach((type: any) => {
          if (element[type] && element[type].length > 0) {
            element[type].forEach((ele: any) => {
              xiaohao += ele["消耗热量"] * ele["次数"] * ele["组数"];
              if (ele["次数"] && ele["组数"]) {
                const num =
                  Number(ele["视频时长"]) *
                  Number(ele["次数"]) *
                  Number(ele["组数"]);
                time += num;
              }
            });
          }
        });
        element["总消耗"] = xiaohao;
        element["总时长"] = time;
      });
    }
    this.$forceUpdate();
  }
  private read(url: any) {
    window.open(url);
  }
  private forceUpdate() {
    this.$forceUpdate();
  }
  private addYundong(val: any) {
    this.addYundongData = {
      分类: val,
      运动: {},
    };
    this.ifShowAddYundong = true;
  }
  private addYundongSure() {
    if (!this.addYundongData["运动"]["运动名称"]) {
      this.$message.warning("请选择运动名称");
      return;
    }
    if (
      !this.detail["方案"]["运动计划"][this.cTab - 1][
        this.addYundongData["分类"]
      ]
    ) {
      this.detail["方案"]["运动计划"][this.cTab - 1][
        this.addYundongData["分类"]
      ] = [];
    }
    this.detail["方案"]["运动计划"][this.cTab - 1][
      this.addYundongData["分类"]
    ].push(this.addYundongData["运动"]);
    this.$forceUpdate();
    console.log(this.addYundongData);
    this.ifShowAddYundong = false;
    this.count();
  }
  private delYundong(type: any, index: any) {
    this.$confirm("确定删除吗？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      this.detail["方案"]["运动计划"][this.cTab - 1][type].splice(index, 1);
      this.count();
    });
  }
  private addOneYundong() {
    this.detail["方案"]["运动计划"].push({});
    this.cTab = this.detail["方案"]["运动计划"].length;
    this.count();
  }
  private delOneYundong(index: any) {
    this.$confirm("确定删除吗？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      this.detail["方案"]["运动计划"].splice(index, 1);
      this.cTab = 1;
    });
  }
  private changeYundongTab(index: any) {
    this.cTab = index + 1;
    this.$forceUpdate();
  }
  private remoteMethodYundong(e: any) {
    const params: any = {
      知识库类型: "运动库",
      运动名称: e,
      类别: this.addYundongData["分类"],
      search_all: true,
    };
    GetYundongList(this, params).then((res: any) => {
      this.yundongs = res.datas.datas;
    });
  }
  private tijiao() {
    if (this.ifCompose) {
      this.$message.warning("请先保存当前修改");
      return;
    }
    let tip: any = "";
    if (!this.detail["提交时间"]) {
      tip =
        "您确定要将该干预方案发送给客户么？发送后系统会短信消息通知，客户可登录番茄心康小程序查看报告内容。";
    } else {
      // 比较保存时间和提交时间
      if (this.detail["提交时间"] > this.detail["保存时间"]) {
        this.$message.warning("当前客户端已是最新干预报告内容，请勿重新提交。");
        return;
      } else {
        tip =
          "您确定要将更新后的干预方案同步发送给客户么？发送后系统会短信消息通知，客户可登录番茄心康小程序查看最新报告内容。";
      }
    }
    if (!tip) {
      return;
    }
    this.$confirm(tip, "温馨提示", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          solution_id: this.detail.solution_id,
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        tijiaoFangan(this, params, loading)
          .then((res: any) => {
            loading.close();
            this.$message.success("提交成功");
            this.getDetail();
          })
          .catch(() => {
            loading.close();
          });
      })
      .catch(() => {});
  }
  private delFile() {
    this.detail.filelist = [];
    this.$forceUpdate();
  }
  /**
   * @description 上传文档
   */
  private uploadFile(d: any) {
    this.ifShowUploadConfirm = false;
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const fileObj = d.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    UploadFile(this, form)
      .then((data: any) => {
        loading.close();
        const arr: any = d.file.name.split(".");
        const nameArr = arr.slice(0, -1);
        const obj = {
          name: nameArr.join("."),
          type: arr[arr.length - 1],
          url: data.full_file_url,
        };
        this.detail.filelist = [obj];
        this.detail["方案"].content = "";
        this.$forceUpdate();
      })
      .catch(() => {
        loading.close();
      });
  }
  private useMoban(item: any) {
    this.detail["周期"] = item["周期"];
    this.detail["方案"] = item["方案"];
    this.detail["名称"] = item["名称"];
    this.ifShowMuban = false;
    this.$forceUpdate();
  }
  private timeChange(e: any) {
    this.detail["周期"][0] = e.startTime;
    this.detail["周期"][1] = e.endTime;
  }
  private delMuban(item: any) {
    this.$confirm("您确定删除该模板么？ ", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const params: any = {
          solution_template_id: item.solution_template_id,
        };
        delSolutionTem(this, params)
          .then((res: any) => {
            loading.close();
            const params2: any = {
              params: {
                类别: this.type,
              },
            };
            getSolutionTemList(this, params2).then((res: any) => {
              this.mubans = res;
            });
          })
          .catch(() => {
            loading.close();
          });
      })
      .catch((e) => {
        console.log("取消了");
      });
  }
  private editorChange(e: any) {
    this.detail["方案"].content = e;
  }
  private openEditMubanName() {
    if (!this.detail["名称"]) {
      this.$message.warning("请填写方案名称！");
      return;
    }
    if (!this.detail["周期"][0]) {
      this.$message.warning("请选择方案周期的开始时间！");
      return;
    }
    if (!this.detail["周期"][1]) {
      this.$message.warning("请选择方案周期的结束时间！");
      return;
    }
    if (this.type == "自定义方案") {
      if (
        !this.detail["方案"].content &&
        (!this.detail.filelist || this.detail.filelist.length == 0)
      ) {
        this.$message.warning("请填写方案内容！");
        return;
      }
    }

    this.mubanName = "";
    this.IfShowEditMubanName = true;
  }
  private openYulan(item: any) {
    this.yulanData = item;
    this.ifShowYulan = true;
  }
  private openMuban() {
    const params: any = {
      params: {
        类别: this.type,
      },
    };
    getSolutionTemList(this, params).then((res: any) => {
      this.ifShowMuban = true;
      this.mubans = res;
    });
  }
  private editNameSure() {
    if (!this.name) {
      this.$message.warning("请输入方案名称");
      return;
    }
    this.IfShowEditName = false;
    this.detail["名称"] = this.name;
  }
  private editMubanSure() {
    if (!this.mubanName) {
      this.$message.warning("请输入模板名称");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      类别: this.type,
      模板名称: this.mubanName,
      名称: this.detail["名称"],
      周期: this.detail["周期"],
      方案: this.detail["方案"],
      filelist: this.detail.filelist,
      方案时间: getCurrentTime("yyyy-MM-dd HH:mm"),
    };
    editSolutionTem(this, params, loading)
      .then((res: any) => {
        this.$message.success("保存成功！");
        loading.close();
      })
      .catch(() => {
        loading.close();
      });
    this.IfShowEditMubanName = false;
  }
  private mubanSure() {
    this.ifShowMuban = false;
  }
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private save() {
    console.log(this.detail);
    return new Promise((resolve, reject) => {
      if (!this.detail["名称"]) {
        this.$message.warning("请填写方案名称！");
        return;
      }
      if (!this.detail["周期"][0]) {
        this.$message.warning("请选择方案周期的开始时间！");
        return;
      }
      if (!this.detail["周期"][1]) {
        this.$message.warning("请选择方案周期的结束时间！");
        return;
      }
      if (this.type == "运动方案") {
        // 需要验证运动方案必填字段
        if (!this.detail["方案"]["频率"]) {
          this.$message.warning("运动目标 — 频率必填！");
          return;
        }
        if (!this.detail["方案"]["强度"]) {
          this.$message.warning("运动目标 — 强度必填！");
          return;
        }
        if (!this.detail["方案"]["时间"]) {
          this.$message.warning("运动目标 — 时间必填！");
          return;
        }
        if (!this.detail["方案"]["方式"]) {
          this.$message.warning("运动目标 — 方式必填！");
          return;
        }
        // 运动计划必须要有
        if (
          !this.detail["方案"]["运动计划"] ||
          this.detail["方案"]["运动计划"].length == 0
        ) {
          this.$message.warning("请添加运动计划！");
          return;
        }
        for (const key in this.detail["方案"]["运动计划"]) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.detail["方案"]["运动计划"],
              key
            )
          ) {
            const element = this.detail["方案"]["运动计划"][key];
            if (!element["课时名称"]) {
              this.$message.warning("课时名称必填！");
              return;
            }
            let hasYundong: any = false;
            for (const typeKey in this.yundongTypes) {
              if (
                Object.prototype.hasOwnProperty.call(this.yundongTypes, typeKey)
              ) {
                const type = this.yundongTypes[typeKey];
                if (element[type] && element[type].length > 0) {
                  hasYundong = true;
                  for (const opt in element[type]) {
                    if (
                      Object.prototype.hasOwnProperty.call(element[type], opt)
                    ) {
                      const d = element[type][opt];
                      if (!d["次数"] || !d["组数"]) {
                        this.$message.warning("运动频次必填！");
                        return;
                      }
                    }
                  }
                }
              }
            }
            if (!hasYundong) {
              this.$message.warning("至少需要添加一项运动！");
              return;
            }
          }
        }
      }
      if (!this.detail.patient_id) {
        this.detail.patient_id = this.patient.patient_id;
      }
      if (!this.detail["保存时间"]) {
        this.detail["保存时间"] = this.detail["提交时间"];
      }
      if (!this.detail["类别"]) {
        this.detail["类别"] = this.type || "自定义方案";
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      saveSolution(this, this.detail, loading)
        .then((res: any) => {
          loading.close();
          this.$store.commit("updateIfCompose", false);
          this.$message.success("保存成功");
          this.detail.solution_id = res;
          this.getDetail();
          resolve(res);
        })
        .catch(() => {
          loading.close();
          reject();
        });
    });
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      solution_id: this.detail.solution_id,
    };
    getSolutionDetail(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.detail = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  // 保存确认弹框保存
  private changeSave() {
    this.ifShowSaveConfirm = false;
    this.save().then(() => {
      if (this.routerPath == "/login") {
        this.$store.commit("updateToken", "");
        this.$store.commit("updateUser", null);
        this.$router.replace("/login");
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        this.$router.push(this.routerPath);
      }
    });
  }
  // 保存确认弹框不保存,切换
  private changeConfirmed() {
    this.ifShowSaveConfirm = false;
    this.$store.commit("updateIfCompose", false);
    if (this.routerPath == "/login") {
      this.$store.commit("updateToken", "");
      this.$store.commit("updateUser", null);
      this.$router.replace("/login");
      setTimeout(() => {
        location.reload();
      }, 1000);
    } else {
      this.$router.push(this.routerPath);
    }
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    this.routerPath = to.fullPath;
    if (this.ifCompose) {
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private getList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      patient_id: this.patient.patient_id,
    };
    getSolutionList(this, params, loading).then((res: any) => {});
  }
  private getType() {
    const params: any = {
      data_type: "运动库类别",
    };
    GetZidian(this, params).then((res: any) => {
      this.yundongTypes = [];
      res["运动库类别"].forEach((ele: any) => {
        this.yundongTypes.push(ele.value);
      });
    });
  }
  private mounted() {
    this.$store.commit("updateIfCompose", true);
    this.getList();
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      if (this.type == "运动方案") {
        this.getType();
      }
    }
    if (this.$route.query.id) {
      this.detail.solution_id = this.$route.query.id;
      this.pageType = "编辑";
      this.getDetail();
    } else {
      this.pageType = "新增";
      // 创建时间默认为当前时间
      this.detail = {
        方案时间: getCurrentTime("yyyy-MM-dd HH:mm"),
        方案: { content: "" },
        周期: ["", ""],
      };
      if (this.$route.query.type == "运动方案") {
        this.detail["方案"] = {
          content: "",
          频率: "",
          强度: "",
          时间: "",
          方式: "",
          运动计划: [],
          达标要求: "",
          下次评估时间: "",
          备注: "",
        };
      }
    }
  }
}
