



















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.time = this.data;
  }
  @Prop()
  private disabled: any;
  @Prop({ type: String, default: "date" })
  private format: any; // 格式
  @Watch("format", { immediate: true })
  private formatChange() {
    if (this.format == "yyyy-MM-dd") {
      this.type = "date";
    }
  }
  @Prop({ type: String, default: "请输入" })
  private placeholder: any; // 提示语
  private type: any = "";
  private time: any = "";
  private disabledStartDate: any = this.beginDate();

  private change() {
    this.$emit("change", this.time);
  }
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() < Date.now();
      },
    };
  }
}
